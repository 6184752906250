* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

.App,
.soccer_root {
  height: 100%;
}

main {
  min-height: calc(100% - 50px);
  box-sizing: border-box;
}

.home_page_news {
  margin-right: 20px;
  width: 790px;
}

.page_footer {
  height: 50px;
  overflow-y: hidden;
  background: #24252f;
  padding: 15px 0;
  border-top: 1px solid #35363f;
  text-align: center;
  color: #fff;
  font-weight: 100;
}

.loader_part_of_page {
  height: 20px;
}

.loader_part_of_page .text {
  font-size: 14px;
  padding-left: 5px;
  font-weight: bold;
}

.loader_part_of_page .showText {
  display: block;
}

.loader_part_of_page .hideText {
  display: none;
}

.score_form_league_editor {
  position: relative;
}

.score_form_league_editor .closeSignIn {
  position: absolute;
  top: 0;
  right: 0;
}

.edit_btn_league {
  border-radius: 3px;
  background-color: #fec722;
  padding: 5px;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 11px;
  font-weight: normal!important;
}

.score_form_row {
  width: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score_form_row.error_field {
  font-size: 13px;
  color: #ff0000;
  height: 13px;
}

.score_form_row.first_level span {
  width: 105px;
}

.score_form_row img {
  width: 50px;
  height: auto;
}

.score_form_league_editor img:first-child {
  margin-right: 5px;
}

.score_form_league_editor img:last-child {
  margin-left: 5px;
}

.score_form_row.space-btw {
  width: 100%;
  justify-content: space-between;
}

.score_form_league_editor .formsInput {
  width: 50px;
  line-height: 12px;
  margin-top: 15px;
}

.score_form_row.pos_center {
  width: 100%;
  justify-content: center;
}

.score_form_row.pos_center .edit_btn_league {
  width: 60px;
  font-size: 14px;
}

.modal.info_picker_for_pdf {
  position: relative;
  /*height: auto;*/
  height: 350px;
  /*width: 320px;*/
}

.info_picker_for_pdf .closeSignIn {
  position: absolute;
  top: 0;
  right: 0;
}
/*HEADERS*/

.header2 {
  font-size: 48px;
  color: #fff;
}

.header3 {
  text-align: center;
  font-size: 28px;
  padding-bottom: 25px;
  border-bottom: 1px solid #ccc;
}

.header4 {
  text-align: center;
  font-size: 21px;
  margin-bottom: 20px;
}

.notFound {
  text-align: center;
  padding-bottom: 20px;
}

.notFound a {
  width: 320px;
  margin: 10px auto 0;
  color: #000;
}

.notFound .header2 {
  color: #fec722;
}

.notFoundImg {
  width: 60%;
  height: auto;
}

.containerForOtherPages .text_article {
  margin-top: 20px;
}

.pdf-links {
  margin-top: 30px;
  padding: 10px 0 20px 0;
}

.pdf-links a {
  text-decoration: none;
  color: #fec722;
  font-weight: bold;
  transition: 0.8s all;
  max-width: 320px;
  display: block;
  margin-top: 10px;
}

.pdf-links a:first-child {
  margin-top: 0;
}

.pdf-links a:hover {
  color: #000000;
}

.pdf-links a:hover span {
  color: #fec722;
}

.pdf-links a span {
  margin-left: 5px;
  color: #000000;
}

/*HEADERS END*/

/*GENERAL FORM*/

.formsInput {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ededed;
  line-height: 26px;
  padding: 6px 12px;
  margin-bottom: 15px;
  background-color: #fff;
  color: #000;
  border-radius: 2px;
}

textarea.formsInput {
  resize: none;
}

.formsInput.error,
.formsSelect.error,
.error {
  border: 1px solid #ff0000;
}

.formsSelect {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ededed;
  height: 40px;
  background-color: #fff;
  padding: 6px 12px;
  margin-bottom: 15px;
  color: #555;
  border-radius: 2px;
}

.formsInput:focus,
.formsSelect:focus {
  outline-color: #fec722;
}

.formsButton {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  background-color: #fec722;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  box-shadow: none;
  color: #000;
  outline: none;
  border-radius: 3px;
  transition: all 0.5s;
}

.formsButton.disabled {
  background-color: #ededed;
}

.formsButton:hover {
  background-color: #755801;
  color: #fff;
}

.formsButton.clicked {
  background-color: #755801;
  color: #fff;
}

.error_field {
  font-size: 12px;
  width: 100%;
  text-align: center;
  display: block;
  color: #ff0000;
  margin-bottom: 5px;
}

.nextTab {
  position: relative;
  cursor: pointer;
  text-align: center;
  width: 100%;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  color: #9b9b9b;
  outline: none;
  border-bottom: 2px solid #ededed;
  transition: all 0.5s;
}

.nextTab:hover {
  color: #000;
  border-color: #fec722;
}

.nextTab.clicked {
  color: #000;
  border-color: #fec722;
}

.forgot_password {
  margin-top: 5px;
}

.forgot_password .formsButton {
  text-decoration: none;
  color: #000;
  text-align: center;
  width: 100%;
  display: block;
}

.forgot_password .formsButton:hover {
  color: #fff;
}

/*GENERAL FORM END*/

.paragraph {
  font-weight: 400;
  font-size: 14px;
  word-wrap: break-word;
}

.background {
  background: url(../images/bg.jpg) no-repeat;
  background-size: cover;
  height: 650px;
}

.background.place-for-timer-new-version {
  height: 220px;
  display: flex;
  justify-content: flex-end;
}

.title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../images/titleBackgound.jpg) no-repeat;
  background-size: cover;
  padding: 48px 0;
}

.title .header2 {
  padding-left: 15px;
}

.title span {
  font-size: 14px;
  font-weight: 700;
  color: #fec722;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
}

.title .quantityRequests {
  padding: 2px;
  display: inline-block;
  margin-top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  border: 1px solid #ededed;
  font-size: 12px;
  color: #000;
  margin-left: 2px;
}

.notVisible {
  display: none !important;
}

.addOpacity0 {
  opacity: 0;
}

.visible {
  display: flex !important;
}

.bg_modal {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  width: 450px;
  height: 200px;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.bg_modal img {
  max-width: 1000px;
  max-height: 500px;
}

.bg_modal .pop-up {
  width: 200px;
  height: 70px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal h3 {
  color: #000;
  border-bottom: none;
}

.modal h3 span {
  color: #fec722;
}

.instagram_h3 {
  color: #000;
  border-bottom: none;
}

.instagram_h3_span {
  color: #fec722;
}


.modal-player-editor-background {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-player-give-access {
  height: 600px;
}

.modal-player-give-access h5 {
  margin-bottom: 20px;
  text-align: center;
}

.modal-player-give-access p {
  margin-bottom: 20px;
}

.modal-player-give-access p:first-child {
  margin-right: 20px;
}

.modal-player-give-access [type='radio']:checked,
.modal-player-give-access [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.modal-player-give-access [type='radio']:checked + label,
.modal-player-give-access [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.modal-player-give-access [type='radio']:checked + label:before,
.modal-player-give-access [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.modal-player-give-access [type='radio']:checked + label:after,
.modal-player-give-access [type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #fec722;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.modal-player-give-access [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.modal-player-give-access [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.modal-player-editor {
  width: 420px;
  position: relative;
  min-height: 300px;
  background: #ffffff;
  padding: 15px;
}

.close-block-modal-player-editor {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-player-editor h5 {
  text-align: center;
  text-transform: uppercase;
}

.modal-player-editor-name {
  margin: 10px 0;
  display: block;
}

.modal-player-editor .formsButton {
  width: 180px;
  margin-left: 27%;
}

/*Header*/
header {
  position: fixed;
  z-index: 99;
  width: 100%;
}

.forMobileMenu {
  display: none;
}

.backgroundHeader {
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7;
}

.emptyHeader {
  height: 111px;
}

.signIn {
  transition: all 0.5s;
}

.signIn .container {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-top: none;
}

.item_sigIn {
  width: calc((100% - 40px) / 2);
  background-color: #fff;
  padding: 20px 60px;
  opacity: 0.4;
}

.item_sigIn:hover {
  opacity: 1;
}

.closeSignIn {
  width: 40px;
  height: 40px;
}

.footer {
  position: absolute;
  bottom: 0;
}

.close {
  font-size: 20px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  outline: none;
  transition: all 0.5s;
}

.close:hover {
  background-color: #fec722;
  border-radius: 3px;
  color: #fff;
  transform: rotate(90deg);
}

.container {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.base {
  position: relative;
}

header .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

header .logo {
  display: block;
  padding: 15px;
}
header .logo img {
  height: 76px;
  width: 76px;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

nav ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
}

nav ul a,
.prototypeLink {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: bold;
  color: #24252f;
  padding: 15px;
  transition: all 0.5s;
}

nav ul a span {
  padding: 2px;
  background: #fec722;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid #ededed;
  transition: all 0.5s;
  width: 20px;
  text-align: center;
  height: 20px;
  display: inline-block;
}

.forRequests:hover span {
  background: #fff;
}

nav ul a:hover,
.prototypeLink:hover,
.login .prototypeLink,
.logout .prototypeLink {
  background-color: #fec722;
}

.otherPages {
  position: relative;
}

.otherPages .dropdownList {
  position: absolute;
  margin-top: 15px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  font-size: 13px;
  display: none;
  transition: all 0.5s;
  min-width: 100%;
  width: 150px;
  border: 1px solid #cccccc;
  border-radius: 0 0 4px 4px;
}

.otherPages .dropdownList.teamDrop {
  min-width: 100%;
  width: 100%;
  z-index: 20;
}

.otherPages .dropdownList.teamDrop a {
  text-align: center;
}

.otherPages:hover .prototypeLink {
  background-color: #fec722;
}

.otherPages:hover .dropdownList {
  display: flex;
}

.otherPages .dropdownList a {
  padding: 10px 6px;
  text-align: left;
}

.otherPages .dropdownList a:hover {
  background-color: #fec722;
}
/*Header_end*/

/*Contacts*/

.howToContactUs {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
}

.item_contact {
  width: calc((100% - 60px) / 3);
  height: 468px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom: 3px solid #fec722;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon_place {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  color: #fec722;
  border: 1px solid #ccc;
  border-radius: 50%;
  font-size: 48px;
  text-align: center;
}

.info_contact {
  margin-top: 45px;
}

.item_contact span {
  text-align: center;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #656565;
}

.contact_form {
  width: 750px;
  margin: 50px auto 0;
}

.contact_form h3 span {
  color: #fec722;
}

.contactUs {
  padding-top: 40px;
  box-sizing: border-box;
  margin-bottom: 110px;
}

.formsInput {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ededed;
  line-height: 26px;
  padding: 6px 12px;
  margin-bottom: 15px;
  color: #555;
  border-radius: 2px;
}

.contactUs textarea {
  resize: none;
}

.contactUs .row input {
  width: 360px;
}

.contactUs .row {
  display: flex;
  justify-content: space-between;
}

/*Contacts_end*/

/*News List*/

.newsList {
  margin: 20px auto 0;
}

.home_page_news .newsList {
  width: 790px;
}

.newsItem {
  float: left;
  margin-left: 80px;
  display: block;
  position: relative;
  border: 1px solid #000;
  height: 345px;
  margin-bottom: 10px;
  width: 345px;
  background-color: #24252f;
  border-radius: 4px;
}

.home .newsItem {
  width: 390px;
  height: 390px;
}

.newsList:after {
  content: '';
  display: block;
  clear: both;
}

.newsItem:first-child,
.newsItem:nth-child(3n + 1) {
  margin-left: 0;
}

.newsItem img {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.5s;
  border-radius: 4px;
}

.newsItem:hover img {
  opacity: 0.4;
}
.newsItem:hover .item_description {
  background-color: transparent;
  color: #fec722;
}

.item_description {
  color: #000;
  position: absolute;
  height: 125px;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s;
  border-radius: 4px;
}

.home .item_description {
  height: 75px;
}

.item_description h4 {
  margin-bottom: 0;
  font-size: 18px;
}

/*News List end*/

/*News Detail*/

.article {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.article .title_article {
  width: 100%;
  font-size: 28px;
}

.article img {
  width: 700px;
  height: 394px;
  margin-bottom: 30px;
}

.text_article {
  width: 100%;
  color: #656672 !important;
  list-style-position: inside;
  font-size: 14px;
}

/*.text_article p,*/
/*.text_article ul,*/
/*.text_article ol,*/
/*.text_article a,*/
/*.text_article sub,*/
/*.text_article sup,*/
/*.text_article span,*/
/*.text_article h1,*/
/*.text_article h2,*/
/*.text_article h3,*/
/*.text_article h4,*/
/*.text_article h5,*/
/*.text_article em,*/
/*.text_article i,*/
/*.text_article table td,*/
/*.text_article table th,*/
/*.text_article address,*/
/*.text_article blockquote {*/
/*font-family: 'Open Sans', sans-serif;*/
/*}*/

.text_article blockquote:before {
  font-family: FontAwesome;
  content: '\f10d';
  color: #656672;
  float: left;
  font-size: 14px;
}

.text_article blockquote {
  color: #24252f;
  margin: 10px 0;
  font-size: 16px;
}

.text_article blockquote p {
  margin-left: 37px;
}

.article .date {
  width: 100%;
  margin: 10px 0 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.article .date span {
  color: #000;
}

.article h4 {
  font-size: 14px;
}

.sharing {
  display: flex;
  flex-direction: row;
}

/*News Detail end*/

/*Academies List*/

.academiesList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.academiesItem {
  width: 224px;
  margin-bottom: 20px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  transition: all 0.5s;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.academiesItem:first-child,
.academiesItem:nth-child(6n) {
  margin-left: 0;
}

.academiesItem:nth-child(5n) {
  margin-right: 0;
}

.academiesItem .backgroundImage {
  width: 222px;
  height: 130px;
}

.academiesItem .logo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.academiesItem .logo .logo_border {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 85px;
  border: none;
  z-index: 2;
}

.academiesItem .logo .logo_border img {
  width: 68px;
  height: 68px;
}

.logo .logo_border {
  width: 140px;
  height: 140px;
  border: 2px solid #fec722;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.logo img {
  width: 134px;
  height: 135px;
}

.logo.academyLogo .logo_border {
  width: 160px;
  height: 160px;
}

.logo.academyLogo .logo_border img {
  width: 158px;
  height: 158px;
}

.requestLogo img {
  width: 100px;
  height: 100px;
}

.academiesItem .description {
  width: 222px;
  padding: 33px 10px 10px 10px;
  position: relative;
  margin-bottom: 54px;
}

.academiesItem .description .svg {
  position: absolute;
  left: 0;
  top: -39px;
  width: 170px;
  height: 39px;
}

.academiesItem .description .header4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.academiesItem .description p {
  min-height: 20px;
  display: block;
  font-size: 14px;
  text-align: center;
}

.readMore {
  width: 160px;
  border: 1px solid #ededed;
  display: block;
  margin: 5px auto;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  bottom: 15px;
  text-decoration: none;
  padding: 7px 0;
  color: #fff;
  font-size: 14px;
  transition: all 0.5s;
  left: 50%;
  transform: translateX(-50%);
}

.academiesItem a:hover {
  color: #fec722;
}

.academiesItem a:hover i {
  color: #000;
}

.academiesItem a i {
  color: #fec722;
  transition: all 0.5s;
}

/*Academies List End*/

/*Academy Detail*/

.academyDetail .details,
.head_detail {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.academyDetail .details .logo,
.head_detail .logo {
  width: 190px;
  height: 190px;
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details .description,
.head_detail .team_description {
  width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.academyDetail .description a {
  text-decoration: none;
  padding: 15px 0;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  min-width: 179px;
  transition: all 0.5s;
}

.academyDetail .description a:hover {
  color: #fec722;
}

.academyDetail .description a:hover i {
  color: #000;
}

.academyDetail .description a i {
  color: #fec722;
  transition: all 0.5s;
}

.academyDetail .details h4 span,
.head_detail .team_description .info_block h4 span {
  font-weight: normal;
  color: #656672;
}

.academyDetail .aboutAcademy,
.head_detail .team_description .description {
  min-height: 100px;
  display: flex;
  align-items: center;
}

.academyDetail h3 {
  background-color: #fec722;
  padding: 15px;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.academyDetail .teams h2 {
  margin-top: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #fec722;
}

.academyDetail .teamAge {
  width: 700px;
  margin: 15px auto;
}

.academyDetail .age {
  width: 50px;
  padding: 5px 0;
  text-align: center;
  background-color: #555555;
  color: #fec722;
  margin-bottom: 10px;
}

.academyDetail .team {
  width: 700px;
  border-bottom: 1px solid #ededed;
  margin: 0 auto;
}

.academyDetail .team .teamItem:last-child {
  border-bottom: none;
}

.academyDetail .teamItem {
  text-decoration: none;
  color: #000;
  display: flex;
  flex-direction: row;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding: 4px;
}

.academyDetail .teamItem .logo {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.academyDetail .teamItem .logo .logo_border {
  width: 110px;
  height: 110px;
}

.academyDetail .teamItem .logo .logo_border img {
  width: 106px;
  height: 106px;
}

.academyDetail .teamItem .description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 550px;
}

.academyDetail .teamItem .description span {
  display: block;
}

.academyDetail .teamItem .description p {
  color: #000;
  font-size: 14px;
}

.academyDetail .teamName {
  font-weight: bold;
}

.academyDetail .teamItem .header_description {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
/*Academy Detail End*/

/*Gallery*/

.goToLink {
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: right;
}

.goToLink a {
  text-decoration: none;
}

.album {
  margin: 0 auto 50px;
  width: 1100px;
  height: 600px;
}

.video {
  width: 540px;
  height: 270px;
  float: left;
  margin-right: 120px;
  margin-bottom: 60px;
}

.video:nth-child(2n) {
  margin-right: 0;
}

.gallery:after {
  content: '';
  display: block;
  clear: both;
}

.album h2,
.video h2 {
  font-weight: bold;
  font-size: 26px;
  background-color: #ededed;
  border-radius: 4px 4px 0 0;
  text-align: center;
}

.album .image-gallery-slide img {
  width: 1100px;
  height: 500px;
}

.image-gallery-image {
  width: 1100px;
}

.image-gallery-slide {
  width: 1100px;
}

.album .image-gallery-thumbnails-container img {
  width: 90px;
  height: 60px;
}

.image-gallery {
  border: 1px solid #fff;
}

.image-gallery .image-gallery-slide .image-gallery-description {
  bottom: 0;
}
/*Gallery End*/

/*PROFILE*/

.navigationProfile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nextNavigation {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.navigationProfile a,
.nextNavigation a {
  width: 150px;
  text-decoration: none;
  text-align: center;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigationProfile .formsButton {
  font-size: 13px;
}

.nextNavigation a {
  margin: 0;
}

.navigationProfile a:first-child {
  margin-left: 0;
}

.navigationProfile a:last-child {
  margin-right: 0;
}

.formsButton.paddingBottom {
  margin-bottom: 20px;
}

.info_profile {
  position: relative;
}

.info_profile,
.minContainer {
  width: 700px;
  margin: 20px auto 20px;
}

.info_profile .title_for_block {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.info_profile .row {
  display: flex;
  flex-direction: row;
}

.info_profile .column {
  width: 450px;
}

.info_profile .column .avatarUser {
  width: 225px;
}

.homeTshirt,
.guestTshirt {
  padding: 0 5px;
  position: relative;
  width: 60px;
  text-align: center;
}

.inRow .homeTshirt,
.inRow .guestTshirt {
  padding: 0;
  position: relative;
  width: 30px;
}

.homeTshirt.active,
.guestTshirt.active,
.homeTshirt:hover,
.guestTshirt:hover {
  background-color: #d4d4d6;
}

.academyDetail .personal_info .homeTshirt:hover,
.academyDetail .personal_info .guestTshirt:hover,
.team_description .homeTshirt:hover,
.team_description .guestTshirt:hover {
  background-color: transparent;
}

.personal_info .row {
  display: flex;
  flex-direction: row;
  min-width: 179px;
}

.academyDetail .details .personal_info h4 {
  min-width: 179px;
}

.homeTshirt__border {
  fill: #ededed;
}

.teamsColors {
  display: flex;
  width: 158px;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 5px;
}

.formsInput.postal-code-style {
  width: 30%;
}

.colorsPicker {
  padding: 2px;
}

.colorsPicker .helpText {
  width: 158px;
  padding: 2px;
  display: block;
  text-align: center;
  margin-bottom: 5px;
  background-color: #fec722;
}

.profileHelpText {
  display: block;
  text-align: center;
}

.date-birth-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.date-birth-row select {
  width: 30%;
}

.location-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.location-row select {
  width: 48%;
}

.info_profile .column .row {
  justify-content: space-between;
}

.ac_teamItem .row.containerRow {
  justify-content: flex-start;
}

.containerRow .inRow {
  align-items: center;
}

.info_profile .column .avatarUser img {
  width: 222px;
  height: 120px;
  border: 1px solid #ededed;
}

.info_profile .column .pickImg {
  width: 225px;
}

.info_profile .avatarUser {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info_profile .avatarUser span {
  font-size: 12px;
}

.info_profile .avatarUser img {
  width: 161px;
  height: 161px;
  margin-bottom: 5px;
}

.info_profile .avatarUser .formsButton {
  padding: 11px;
  font-size: 13px;
}

.pickImg {
  position: relative;
  width: 161px;
  height: 40px;
}

.pickImg:hover .formsButton {
  background-color: #755801;
  color: #fff;
}

.info_profile .avatarUser input[type='file'] {
  position: absolute;
  z-index: 1;
  opacity: 0;
  width: 161px;
  height: 40px;
  top: 0px;
  left: 0;
}

#upload-photo {
  top: 0;
}

.iDPhoto {
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-around;
  margin-top: 14px;
  position: relative;
}

.avatarUser .iDPhoto .showIdImg {
  width: 30px;
  padding: 3px 4px;
  height: 38px;
  margin-top: 30px;
}

.game {
  border: 1px solid #fec722;
  border-radius: 4px;
  background-color: rgba(231, 231, 231, 1);
  margin-bottom: 10px;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gamesList {
  margin-top: 30px;
}

.gamesList .game .team,
.gamesList .game .info_game {
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game .confirm .sure {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.game .confirm .sure span {
  cursor: pointer;
  background: #ff0000;
  padding: 3px;
  width: 40px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
}

.game .confirm .sure span:first-child {
  background-color: #28b742;
}

.gamesList .game .info_game {
  padding: 2px;
}

.referee a,
.place a {
  text-decoration: none;
  color: #000;
  transition: all 0.5s;
}

.referee a:hover,
.place a:hover {
  text-decoration: underline;
}

.refereePick {
  width: 100%;
  text-align: center;
}

.gamesList .game .team {
  flex-direction: row;
  justify-content: space-between;
}

.gamesList .game .team h3 {
  min-width: 180px;
  text-align: center;
}

.gamesList .game .info_game h4 {
  margin: 4px;
}

.label_h4 {
  display: block;
  text-align: center;
}

.gamesList .game .info_game h4 span,
.team_information h4 span {
  color: #755801;
}

.gamesList .game .info_game .formsSelect,
.refereePick .formsButton {
  height: 24px;
  padding: 3px 2px;
  margin-bottom: 0;
}

.myTeam {
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.info_profile .label,
.label-modal-editor {
  background-color: #fec722;
  border-radius: 4px 4px 0 0;
  padding-left: 4px;
}

.info_profile .label + select,
.label-modal-editor + select {
  border-radius: 0 0 4px 4px;
}

.resultsRequest {
  margin: 0 auto;
}

.requestItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ededed;
  background-color: #fff;
  border-radius: 4px;
  padding: 7px;
  margin-top: 5px;
}

.requestItem:first-child {
  margin-top: 20px;
}

.requestItem .logo {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requestItem .logo .logo_border {
  width: 100px;
  height: 100px;
}

.requestItem .logo .logo_border img {
  width: 65px;
  height: 65px;
}

.requestItem .formsSelect,
.requestItem .formsInput {
  margin-bottom: 0;
}

.requestItem .team {
  width: 200px;
}

.requestItem .other {
  width: 150px;
}

.requestItem h4 {
  color: #fec722;
}

.requestItem h4 span {
  color: #000;
}

.minContainer a {
  display: block;
  text-align: center;
  text-decoration: none;
}

.minContainer h4 {
  color: #fff;
}

.insuranceContainer h4 {
  color: #000;
}

.minContainer p.descriptionForInsurance {
  padding: 7px;
  background-color: #cccccc;
  margin-top: 15px;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-bottom: 15px;
}

.formAddFile {
  margin: 15px 0;
  border: 1px solid #ededed;
  padding: 10px;
}

.pickFile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.pickFile a {
  width: 320px;
}

.pickFile .addFile {
  width: 320px;
  position: relative;
}

.pickFile .addFile input {
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.ac_teamItem {
  padding: 5px;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
}

.ac_teamItem .row:hover .action_with_team {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.ac_teamItem .playerItem:hover .action_with_player {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.action_with_team {
  z-index: 3;
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.action_with_player {
  z-index: 3;
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

.action_with_team .formsButton,
.action_with_player .formsButton {
  width: 100px;
}

.notCreated {
  text-align: center;
  color: #000;
}

.action_with_team .formsButton:last-child,
.action_with_player .formsButton:last-child {
  background: #ff0000;
}

.ac_teamItem:last-child {
  margin-bottom: 20px;
}

.ac_teamItem img {
  width: 84px;
  height: 84px;
}

.ac_teamItem .logo {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ac_teamItem .logo .logo_border {
  width: 90px;
  height: 90px;
}

.ac_teamItem .row {
  position: relative;
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
}

.ac_teamItem .info_team {
  width: calc(100% - 100px);
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ac_teamItem.ref-teams-list-item {
  /*background-color: #fec722;*/
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ededed;
  margin-top: 0;
}

.organizer-header-manager-wrapper.ref-teams-list {
  padding: 3px 0 0 0;
}

.ac_teamItem.ref-teams-list-item {
  cursor: auto;
}


.organizer-header-manager-wrapper.ref-teams-list {
  border-bottom: none;

}
.ref-teams-list-container {
  /*background-color: #28b742;*/
  margin-top: 10px;
}

.ref-teams-list-item .row {
  /*justify-content: space-between;*/
  align-items: center;
}

.ref-teams-list-item h4 {
  font-weight: normal;
}

.ref-teams-list-item .name-surname-for-staff {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
}

.ref-teams-list-item.header-of-table {
  border-radius: 7px 7px 0 0;
  background-color: #ededed;
}

.ref-teams-list-item .ref-teams-list-item-btn-edit {
  border-radius: 3px;
  background-color: #fec722;
  padding: 5px;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 11px;
}

.ac_teamItem.ref-teams-list-item h4 span {
  font-weight: bold;
  color: #000;
}

.ac_teamItem.ref-teams-list-item h4.name-surname-for-staff span {
  font-weight: normal;
}

.ref-teams-list-item h4:nth-child(1) {
  width: 35%;
  margin-right: 10px;
}

.ref-teams-list-item h4:nth-child(2) {
  width: 10%;
  margin-right: 10px;
}

.ref-teams-list-item h4:nth-child(3) {
  width: 14%;
  margin-right: 10px;
}

.ref-teams-list-item h4:nth-child(4) {
   width: 18%;
   margin-right: 10px;
 }

.ref-teams-list-item h4:nth-child(5) {
  width: 18%;
}

.ref-teams-list-item h4:nth-child(6) {
  width: 18%;
}

.teamInfoForm-container {
  width: 100%;
}

.teamInfoForm-container .closeSignIn {
  position: absolute;
  right: 0;
  top: 0;
}

.teamInfoForm-container .column {
  width: 100%;
  margin-right: 45px;
}

.teamInfoForm-container .three-in-row .formsInput, .teamInfoForm-container .three-in-row .formsSelect {
  width: 30%;
}

.minContainer .ac_teamItem h4 {
  color: #000;
  margin-right: 20px;
}

.command_structure {
  margin-top: 7px;
}

.command_structure .structure {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.command_structure > h4 {
  background-color: #ededed;
  padding: 4px;
  border-radius: 0 0 4px 4px;
}

.ac_teamItem h4 span {
  color: #fec722;
}

.playerItem img {
  width: 70px;
  height: 70px;
}

.playerItem {
  width: 320px;
  padding: 5px;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.other.miniInput h4 {
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.other.miniInput .formsInput,
.other.miniInput .formsSelect {
  height: 26px;
  width: 71px;
  margin-left: 5px;
  padding: 4px 12px;
}

.other.miniInput .formsSelect {
  padding: 4px 6px;
  width: 57px;
}

.requestConfirm {
  display: flex;
  flex-direction: row;
  color: #fff;
}

.requestConfirm button:last-child {
  background-color: #ff0000;
}

.requestConfirm button:first-child {
  margin-right: 5px;
  background-color: #28b742;
}

/*Referees page for moderator*/

.referees-head-block {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.referees-head-block a {
  cursor: pointer;
  width: 300px;
  padding: 15px;
  background-color: #fec722;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  box-shadow: none;
  color: #000;
  outline: none;
  border-radius: 3px;
  transition: all 0.5s;
}

.referees-list-block {
  width: 1000px;
  margin: 20px auto;
}

.item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.referees-list-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #cccccc;
}

.referees-list-item .link-to-statistic {
  text-decoration: none;
  color: #000000;
  padding: 5px;
  border: 1px solid #fec722;
  border-radius: 5px;
  margin-top: 5px;
}

.referees-list-item .link-to-statistic h5.title-stat {
  margin: 0;
}

.referees-list-item.item-row {
  align-items: flex-start;
}

.referees-list-item h5.title-stat {
  width: 100%;
  margin-top: 5px;
}

.referees-list-item .araz-email-block {
  min-width: 288px;
}

/*.referees-list-item:nth-child(even) {*/
/*background: #fbfbfb;*/
/*}*/

/*.referees-list-item:nth-child(odd) .referee-statistic {*/
/*background: #fbfbfb;*/
/*}*/

.referees-list-item img {
  width: 50px;
  height: 50px;
}

.referees-list-item span {
  min-width: 115px;
  display: block;
  margin-left: 15px;
}

.referees-list-item .referee-statistic {
  justify-content: flex-start;
  width: 650px;
  background: #ffffff;
  padding: 5px;
  flex-wrap: wrap;
  margin: 5px 0 0;
}

.referees-list-item .referee-statistic span,
.referees-list-item .referee-statistic h5 {
  margin-top: 0;
  font-size: 10px;
}

.week-statistic {
  width: 320px;
}

.referees-list-item span.week-stat-count {
  min-width: 45px;
}

.games-statistic-about-referee {
  padding-bottom: 20px;
}

.games-statistic-about-referee .game-list-for-referee {
  margin-top: 10px;
}

.games-statistic-about-referee h5 {
  text-align: center;
  color: #fff;
  padding: 4px;
  background-color: #fec722;
  margin-bottom: 10px;
  border-radius: 4px;
}

.games-statistic-about-referee .section {
  margin-bottom: 20px;
  border: 1px solid #ededed;
  border-radius: 4px;
}

.section .stats {
  padding: 5px 12px;
  background-color: #28b742;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
}

.section .stats span {
  font-weight: bold;
  min-width: 45px;
  display: block;
  font-size: 12px;
}

/*Referees page for moderator end*/
/*SEND EMAIL BY MODERATOR*/

.send-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.send-email h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}

.send-email h3 {
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
}

.form-to-send {
  width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.send-email .formsButton {
  width: 300px;
  margin-bottom: 30px;
}

.send-email-list-referees {
  width: 630px;
  margin: 10px auto 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.send-email-referees-item {
  width: 310px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 15px 5px 15px 45px;
  /*background-color: #fbfbfb;*/
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.send-email-referees-item span {
  /*min-width: 120px;*/
  display: block;
  text-align: center;
}

.name-margin-right {
  margin-right: 10px;
}

.send-email-referees-item span.checkmark {
  min-width: 25px;
  position: absolute;
  top: 12px;
  left: 10px;
  height: 25px;
  /*width: 25px;*/
  background-color: #eee;
}

.send-email-referees-item:hover .checkmark {
  background-color: #ccc;
}

.send-email-referees-item.picked .checkmark {
  background-color: #fec722;
}

.send-email-referees-item .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.send-email-referees-item.picked .checkmark:after {
  display: block;
}

.send-email-referees-item .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.select-all-for-email {
  width: 100%;
  display: flex;
  justify-content: center;
}

.select-all-for-email .send-email-referees-item {
  width: 150px;
  margin-bottom: 0;
}

/*SEND EMAIL BY MODERATOR end*/

/*HISTORY EMAILS*/

.history-emails {
  width: 600px;
  margin: 0 auto;
}

.history-send-emails h1 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}

.history-emails-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  padding: 10px;
  text-decoration: none;
  color: #000000;
}

.history-emails-item span {
  min-width: 150px;
  display: block;
}

.history-emails-item span:first-child {
  font-weight: bold;
}

/*HISTORY EMAILS end*/

/*EMAIL DETAIL*/

.email-details {
  width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-details h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.email-details h3 {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ededed;
  line-height: 26px;
  padding: 6px 12px;
  margin-bottom: 15px;
  color: #555;
  border-radius: 2px;
}

.email-details p {
  width: 100%;
  min-height: 170px;
  box-sizing: border-box;
  border: 1px solid #ededed;
  line-height: 26px;
  padding: 6px 12px;
  margin-bottom: 15px;
  color: #555;
  border-radius: 2px;
}

.receivers-block-and-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.receivers-block-and-date .receivers-block {
  display: flex;
  flex-direction: column;
}

.receivers-block span {
  min-width: 150px;
  display: block;
  text-align: center;
}

.receivers-block-item {
  display: flex;
  flex-direction: row;
}

/*EMAIL DETAIL end*/

.game-list-for-referee {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.game-list-for-referee .game-referee-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.game-item-for-referee {
  width: 290px;
  padding: 10px;
  border: 1px solid #bdbdc0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  position: relative;
}

.mod_game_referee_error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mod_game_referee_error_content {
  font-size: 14px;
  background-color: #fff;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #ff0000;
}

.mod_game_referee_error_content h5 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #ff0000;
}

.mod_game_referee_error_btn {
  text-align: center;
  margin: 10px auto 0;
  width: 60px;
  padding: 3px 8px;
  border-radius: 7px;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  background-color: #fec722;
}

.list-referees-in-game-block {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.game-item-for-referee span {
  margin: 4px 0;
}

.list-referees-in-game-block .referee-was-picked {
  display: flex;
  flex-direction: column;
}

.list-referees-in-game-block .referee-was-picked span {
  margin: 4px 20px;
}

.game-item-for-referee .important-info {
  font-weight: bold;
}

.game-item-for-referee .team-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.game-item-for-referee .team-title h2 {
  width: 100px;
  font-size: 14px;
}

.game-item-for-referee .team-title img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.game-item-for-referee .head-referee-list-of-refs {
  margin-top: 10px;
  position: relative;
}

/*.head-referee-list-of-refs select {*/
/*width: 100%;*/
/*background: #ffffff;*/
/*}*/

.head-referee-list-of-refs .btn-select-from-list-of-referee {
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.head-referee-list-of-refs .list-of-referee {
  display: none;
  height: 92px;
  z-index: 5;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  border: 1px solid #fec722;
  border-radius: 5px;
  position: absolute;
  background: #ffffff;
}

.head-referee-list-of-refs:hover .list-of-referee {
  display: block;
}

.head-referee-list-of-refs:hover .btn-select-from-list-of-referee {
  background: rgba(254, 199, 34, 0.7);
  color: #ffffff;
}

.head-referee-list-of-refs:hover .fa-chevron-down {
  transform: rotate(180deg);
}

.head-referee-list-of-refs .list-of-referee .item-for-list-of-referee {
  border-bottom: 1px solid #ededed;
  padding: 5px;
  cursor: pointer;
}

.list-of-referee .item-for-list-of-referee:hover {
  background: rgba(0, 0, 0, 0.1);
}

.referee-wants {
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.referee-item p {
  font-size: 12px;
  padding: 4px;
  background: #28b742;
  color: #ffffff;
  border-radius: 3px;
}

.referee-wants .referee-picker {
  width: 30px;
  height: 30px;
  border: 1px solid #fec722;
  border-radius: 3px;
  cursor: pointer;
}

.status-referee-pick {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.hover-referee-chooses {
  display: none;
  padding: 20px;
  position: absolute;
  top: -50px;
  left: 7px;
}

.referee-wants .referee-picker:hover .hover-referee-chooses {
  display: block;
}

.referee-wants .referee-chooses {
  display: flex;
  /*position: absolute;*/
  border: 1px solid #bdbdc0;
  /*display: flex;*/
  flex-direction: row;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 4px;
  /*top: -30px;*/
}

.hover-referee-chooses:hover {
  display: block;
}

.referee-wants .referee-chooses:after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border: 1px solid #bdbdc0;
  border-top: none;
  border-left: none;
  position: absolute;
  bottom: 15px;
  left: 48%;
  transform: rotate(45deg);
}

.referee-chooses .referee-chooses-item {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  background-color: #81c342;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}

.referee-chooses .referee-chooses-item:nth-child(2) {
  background-color: #0c416f;
}

.referee-chooses .referee-chooses-item:last-child {
  background-color: #e36463;
}

.referee-want-judged {
  background-color: #81c342;
}

.referee-not-want-judged {
  background-color: #e36463;
}

.referee-does-know {
  background-color: #0c416f;
  font-weight: bold;
}

.assign-referees-block {
  margin-top: 15px;
}

.assign-referees-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 31px;
  /*justify-content: space-between;*/
  /*padding-right: 10px;*/
}

.assign-referees-item span {
  font-size: 14px;
  display: block;
  width: 145px;
}

.assign-referees-item .status-assign-referee.want-judged {
  width: 16px;
  height: 16px;
  background-color: #28b742;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.assign-referees-item .status-assign-referee.maybe-judged {
  width: 16px;
  height: 16px;
  background-color: #0c416f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
}

.assign-referees-item .status-assign-referee.not-want-judged {
  width: 16px;
  height: 16px;
  background-color: #e36463;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.status-assign-referee.not-want-judged.delete-pick-for-moder {
  margin-right: 0;
  margin-left: 4px;
  display: none;
  cursor: pointer;
}

.assign-referees-item:hover
  .status-assign-referee.not-want-judged.delete-pick-for-moder {
  display: flex;
}

.assign-referees-item:hover .pick-referee-for-head-referee {
  opacity: 1;
}

.assign-referees-item.has-role .pick-referee-for-head-referee {
  display: block;
}

.pick-referee-for-head-referee {
  display: block;
  margin-left: 5px;
}

.pick-referee-for-head-referee span {
  cursor: pointer;
  display: block;
  border-radius: 3px;
  padding: 3px;
  width: 27px;
  font-size: 12px;
  border: 1px solid #28b742;
  color: #28b742;
}

.picked-referee-for-opacity {
  opacity: 0.3;
}

.approve-you-pick {
  width: 200px;
  margin: 20px auto 20px;
  text-align: center;
}

/*PROFILE end*/

/*TEAMS*/

.teams_filter {
  width: 300px;
  display: flex;
  flex-direction: row;
}

.teams_list {
  margin-top: 30px;
}

.notFoundTeams {
  margin-top: 65px;
  text-align: center;
}

.teams_list .team_item {
  border-bottom: 1px solid #fec722;
  border-radius: 4px 4px 0 0;
  text-decoration: none;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  transition: all 0.5s;
}

.teams_list .team_item:last-child {
  margin-bottom: 20px;
}

.teams_list .team_item:hover,
.player:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.team_item h4 span,
.player h4 span {
  font-weight: normal;
}

.team_item h4,
.player h4 {
  color: #000;
}

.team_info {
  width: 550px;
  height: 140px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.head_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.head_info h4 {
  width: 250px;
}

.head_info .row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

/*TEAM DETAIL*/

.team_detail .header3 {
  border-color: #fec722;
  margin-bottom: 20px;
}



.head_detail .description {
  /*width: 320px;*/
}

.head_detail h4 span {
  color: #000;
  font-weight: normal;
}

.minContainer a.player,
.minContainer div.player {
  border-bottom: 1px solid #ededed;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.player img {
  width: 120px;
  height: 120px;
}

.rosters-page {
  margin: 20px 0;
}

.teams-player-block-manager {
  border: 1px solid #fec722;
  padding: 5px;
  border-radius: 7px;
  margin-bottom: 10px;
}

.rosters-page .organizer {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 3px 0 0 3px;
}

.organizer-header-manager-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px 10px 10px;
  border-bottom: 1px solid #fec722;
}

.organizer-main-container .label-modal-editor {
  font-size: 12px;
}

.spec-btn-for-manager-team {
  cursor: pointer;
  padding: 5px;
  color: #ff0000;
  display: flex;
  align-items: center;
  width: 24px;
  /*margin-bottom: 15px;*/
}

.organizer-header-manager-block {
  flex-direction: row;
  display: flex;
  padding: 5px 0;
}

.organizer-header-manager-block .header-manager-item {
  position: relative;
  padding: 4px 16px;
  /*border-width: 1px;*/
  /*border-bottom-width: 2px;*/
  /*border-style: solid;*/
  border-radius: 4px;
  margin-left: 10px;
  border-color: #cccccc;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fec722;
}

.header-manager-item .input_type_file{
  position: absolute;
  top: 0;
  left: 0;
  width: 166px;
  height: 27px;
  opacity: 0;
}

.organizer-header-manager-block .header-manager-item:hover {
  background-color: #755801;
  color: #fff;
}

.organizer-header-manager-block .header-manager-item span {
  margin-left: 5px;
  font-size: 16px;
}

.organizer-tabs-ages {
  width: 145px;
  /*border-right: 1px solid #0c416f;*/
  display: flex;
  flex-direction: column;
}

.organizer-tabs-ages .tabs-age-item {
  padding: 17px;
  display: flex;
  align-content: center;
  justify-content: center;
  /*border-bottom: 1px solid #f2f2f3;*/
  border-right: 1px solid #f2f2f3;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}

.organizer-tabs-ages .tabs-age-item.empty-tab {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.04);
  padding: 18px;
  display: flex;
  align-items: center;
  /*background-color: #fec722;*/
  border-top-left-radius: 7px;
  border-right: none;
  /*border-right: 1px solid #fff;*/
  cursor: default;
  border-bottom: none;
}

.organizer-main-wrapper {
  width: 100%;

}

.organizer-header-tabs {
  display: flex;
  flex-direction: row;
  margin-left: -1px;
}

.header-tabs-team-item {
  padding: 25px 30px 25px 30px;
  height: 40px;
  /*border-right: 1px solid #f2f2f3;*/
  border-bottom: 1px solid #f2f2f3;
  border-right: 1px solid #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-tabs-team-item img {
  width: 20px;
  margin-right: 5px;
}

.organizer-main-container {
  padding: 10px;
  margin-bottom: 30px;
  background-color: #fff;
  min-height: 400px;
}

.organizer-main-container .info_profile {
  width: calc(100% - 30px);
  flex-direction: row;
  display: flex;
  margin: 0;
}

.organizer-main-container .info_profile .column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.organizer-main-container .avatarUser {
  width: 220px;
  margin-left: 10px;
  align-items: flex-start;
}

.organizer-main-container .column-roster-form-edit-files .avatarUser .formsButtonWithIcon {
  height: 38px;
  width: 36px;
}

.style-for-select-inside select {
  width: 100%;
}
/*.column-roster-form-edit-files {*/
  /*margin-right: 30px;*/
/*}*/

.organizer-main-container .date-birth-row .fieldForRow {
  width: 30%;
}

.organizer-main-container .partlyLoader {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  align-items: center;
  justify-content: center;
}

.organizer-main-container .partlyLoader h3 {
  text-align: center;
  padding: 10px;
  color: #28b742;
  background-color: #fff;
  border-radius: 7px;
}

.organizer .currentAge {
  border: 1px solid #ededed;
  border-right: none;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  font-weight: bold;
  background-color: #fff;
  /*box-shadow: inset 2px 2px 5px 0px rgba(0,0,0,0.32);*/
}

.organizer .currentTeam {
  border: 1px solid #ededed;
  background-color: #fff;
  border-bottom: none;
  font-weight: bold;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}


.roster-player-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #f2f2f3;
}

.roster-player-item .roster_player_name {
  width: 25%;
}

.roster-player-item .roster_player_DOB {
  width: 11%;
}


.roster-player-item .editBtn {
  border-radius: 3px;
  background-color: #fec722;
  padding: 5px;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 11px;
  /*margin-bottom: 5px;*/
}

.roster-player-item img {
  width: 70px;
  height: 70px;
  margin-left: 10px;
}

.roster-player-item.player:hover {
  background-color: transparent;
}

.roster-player-item.player h4 {
  text-align: left;
}

.roster-player-item .checkedIdDocument {
  width: 2%;
  color: #fec722!important;
}

.import-players-wrapper {
  position: relative;
  width: 700px;
  height: 400px;
  padding: 10px;
  display: block;
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: space-around;*/
  /*align-items: flex-start;*/
}

.import-container-row {
  width: 100%;
  height: 89%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.import-players-wrapper .closeSignIn {
  position: absolute;
  top: 0;
  right: 0;
}

.import-players-container {
  width: 48%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 7px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.picked-players-to-roster {
  background-color: #ededed;
}

.collapsible-team-item {
  border: 1px solid #ededed;
  border-radius: 8px;
  margin-bottom: 5px;
}

.collapsible-team-item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 7px;
  background-color: #0c416f;
  color: #fff;
}

.collapsible-team-item-title.current-coll-tab {
  background-color: #fec722;
  border-radius: 7px 7px 0 0;
  color: #000;
  font-weight: bold;
}

.current-coll-tab .extra_class {
  transform: rotate(180deg);
}

.collapsible-team-item-roster {

  padding: 0 5px 5px 5px;
}

.coll-team-roster-player {
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  padding: 3px;
  align-items: center;
}

.coll_team-roster-player-radiobutton {
  width: 15px;
  height: 15px;
  border: 1px solid #fec722;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.coll_team-roster-player-radiobutton .coll_team-roster-player-radiobutton-content {
  width: 11px;
  height: 11px;
  background-color: #28b742;
  border-radius: 50%;
}

.coll-team-roster-player:first-child {
  border-top: none;
}

.picked-players-to-roster-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 3px 10px;
  margin-bottom: 2px;
}

.picked-players-to-roster-item .picked-player-name {
  margin-right: 10px;
}

.picked-players-to-roster-item .picked-player-team {
  font-size: 12px;
  font-weight: bold;
}

.import-container-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.import-container-btn span {
  padding: 5px 30px;
  background-color: #fec722;
  font-size: 13px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
}

.pdf_roster_list {
  width: 300px;
  overflow-y: auto;
  padding: 5px 15px;
}

.pdf_roster_list h5 {
  text-align: left;
  margin-bottom: 4px;
}

.gen_pdf_roster_btn_container {
  margin-top: 15px;
}

.pdf_roster_item {
  /*background-color: aqua;*/
  border: 1px solid #ededed;
  border-radius: 7px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  padding: 2px;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
}

.pdf_roster_item > .pdf_roster_isPicked {
  margin-right: 5px;
}

.icon_staff_pdf_team_roster {
  font-size: 10px;
  font-weight: bold;
  padding: 2px;
  border: 1px solid #fec722;
  color: #fec722;
  border-radius: 3px;
  margin-right: 5px;
}

.info_picker_for_pdf .action_panel {
  display: flex;
  flex-direction: row;
  width: 300px;
  margin-bottom: 7px;
  padding-left: 15px;
}

.info_picker_for_pdf .action_panel_btn {
  font-size: 12px;
  margin-right: 7px;
  /*border: 1px solid #ededed;*/
  background-color: #fec722;
  padding: 2px 6px;
  border-radius: 7px;
  cursor: pointer;
}

.pdf_roster_isPicked {
  width: 14px;
  height: 14px;
  border-radius: 10px;
  border: 1px solid #ededed;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*TEAM DETAIL end*/

/*TEAMS end*/

/*DETAIL PAGE*/

.header4.ref {
  color: #fff;
}

.info {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
}

.info h4.showDate {
  font-size: 21px;
}

.info h4.showDate span {
  font-size: 16px;
}

.info.changesInfo {
  margin-top: 20px;
}

.changesInfo .twoRows {
  display: flex;
  flex-direction: column;
}

.changesInfo .twoRows h3 {
  margin-left: 20px;
  line-height: 39px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 18px;
  width: 765px;
  font-size: 28px;
  padding-left: 55px;
}

.player_avatar {
  position: relative;
}

.player_avatar .corner_team {
  height: 89px;
  width: 160px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 15px 2px 5px;
  background: rgba(255, 255, 255, 0.7);
}

.player_avatar .corner_team:after {
  content: '';
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 89px 0;
  border-color: transparent transparent rgba(255, 255, 255, 0.7) transparent;
}

.player_avatar .corner_team img {
  width: 80px;
  height: 80px;
}

.info_player {
  width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.info.minContainer {
  align-items: center;
}

.info.minContainer h4 {
  color: #000;
}

.description,
.details .aboutAcademy,
.head_detail .team_description .description {
  /*width: 420px;*/
  padding: 5px;
}

.info_player .description p,
.academyDetail .description .aboutAcademy p,
.head_detail .team_description .description p {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #656672;
}

.player_avatar {
  width: 370px;
  height: 370px;
}

.info img {
  width: 370px;
  height: 370px;
}

.info_player .personal_info,
.details .personal_info,
.head_detail .team_description .info_block {
  font-size: 14px;
  min-width: 320px;
  background-color: #fcfcfc;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.details .personal_info {
  justify-content: flex-start;
  align-items: flex-start;
}

.personal_info.refereeInfo h4 span {
  font-weight: normal;
}

.info_player .personal_info .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.info_player .personal_info .row:last-child {
  margin-bottom: 0;
}

.info_player .personal_info h4,
.details .personal_info h4,
.head_detail .team_description .info_block h4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: normal;
  color: #656672;
  min-width: 100px;
}

.head_detail .team_description .info_block .row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.head_detail .team_description .info_block .row:last-child {
  margin-top: 15px;
  justify-content: flex-start;
  padding-left: 20px;
}

.details .personal_info h4,
.head_detail .team_description .info_block h4 {
  font-weight: bold;
  color: #000000;
}

.details .personal_info h4:last-child {
  width: 179px;
  text-align: left;
  margin-top: 15px;
}

.info_player .personal_info h4 span {
  font-weight: bold;
  margin-bottom: 4px;
}

.info h4 {
  margin-bottom: 5px;
}

.info h4 span {
  color: #000;
}

.minContainer a.playerTeam {
  padding: 10px;
  border: 1px solid #ededed;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  transition: all 0.5s;
}

.minContainer a.playerTeam:hover {
  background-color: rgba(0, 0, 0, 1);
}

/*DETAIL PAGE end*/

/*Archive of Leagues*/

.league-date-title {
  position: absolute;
  bottom: 0;
  text-align: right;
  color: #ffffff;
  padding-right: 10px;
  padding-bottom: 5px;
}

.leaguesList {
  margin-top: 20px;
}

.text-align {
  text-align: center;
}

.leaguesList:after {
  content: '';
  display: block;
  clear: both;
}

.leagueItem {
  width: 320px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #cccccc;
  padding: 10px;
  text-align: center;
  background-color: #ededed;
  float: left;
  margin-left: 100px;
  margin-bottom: 30px;
}

.leagueItem:first-child,
.leagueItem:nth-child(3n + 1) {
  margin-left: 0;
}

.leagueItem a {
  display: block;
  text-decoration: none;
}

.weekGames .gamesList {
  margin-top: 0;
}

.gamesList.leagueGames .logo {
  width: 80px;
  height: 80px;
}

.gamesList.leagueGames .logo .logo_border {
  width: 80px;
  height: 80px;
  border: 2px solid #d6d6d6;
}

.gamesList.leagueGames .logo .logo_border img {
  width: 74px;
  height: 74px;
}

.gamesList.leagueGames .game {
  padding: 5px 30px;
  border: 1px solid #f2f2f3;
  background: #fff;
  border-radius: 0;
  margin-bottom: 0;
}

.league_detail .weekGames {
  margin-bottom: 20px;
}

.league_detail .weekGames h3 .color-day {
  color: #ff0000;
}

.league_detail .weekGames h3 .color-day-other {
  color: #d6a40e;
}

.gamesList.leagueGames .game:nth-child(even) {
  background: #fbfbfb;
}

.gamesList.leagueGames .game .info_game .score,
.gamesList.leagueGames .game .info_game .referee,
.gamesList.leagueGames .game .info_game .place,
.gamesList.leagueGames .game .team_information h4:not(:first-child) {
  font-weight: normal;
}

.gamesList.leagueGames .game .info_game .score.bold-score {
  font-weight: bold;
  font-size: 19px;
}

.one_column_fix {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.one_column_fix.withText {
  align-items: center;
}

.gamesList.leagueGames .game .info_game span {
  font-weight: bold;
  color: #000000;
}

.gamesList.leagueGames .game .info_game .notLink_refereeDetail {
  font-weight: normal;
}

.gamesList.leagueGames .game .team h4 {
  width: 220px;
  text-align: center;
}

.weekGames .gamesList .game:first-child {
  border-radius: 0 0 4px 4px;
}

.weekGames h3 {
  text-align: center;
  padding: 3px;
  border-radius: 4px 4px 0 0;
  font-size: 17px;
  background-color: #ededed;
}

.weekTournament > h2 {
  text-align: center;
  color: #fff;
  padding: 4px;
  background-color: #fec722;
  margin-bottom: 10px;
  border-radius: 4px;
}

.navigationCalendar {
  width: 200px;
  margin: 0 auto 20px;
}

.leagueTable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-size: 13px;
}

.leagueTable + .leagueTable {
  margin-bottom: 20px;
}

.select-for-moderator-for-league {
  display: flex;
  flex-direction: row;
}

.select-for-moderator-for-league .refereePick {
  margin-left: 5px;
  margin-top: -3px;
}

.error_text.error-referee {
  font-size: 10px;
}

.row-referee {
  display: flex;
  flex-direction: row;
  position: relative;
}

.row-referee span {
  position: absolute;
  right: -20px;
}

.row-referee svg {
  margin: 4px 0 0 3px;
  color: #fec722;
  transition: 1s all;
}

.leagueTable.headerTable .logo {
  width: 40px;
  height: 10px;
}

.leagueTable.headerTable .logo .logo_border {
  width: 40px;
  height: 10px;
}

.leagueTable thead {
  color: #000;
  border-bottom: 1px solid #fec722;
}

.leagueTable thead th {
  position: relative;
}

/*MAIN TABLE*/

.leagueTable thead th:first-child {
  color: #fff;
  width: 341px;
}

.leagueTable .noSeeHeader {
  visibility: hidden;
}

.leagueTable.headerTable thead th:last-child:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 12px 10px;
  border-color: transparent transparent #fec722 transparent;
}

.leagueTable tbody tr {
  border: 1px solid #f2f2f3;
}

.leagueTable td,
.leagueTable th {
  padding: 5px;
}

.leagueTable tbody tr:nth-child(even) {
  background-color: #fbfbfb;
}

.leagueTable tbody tr td {
  color: #bdbdc0;
  font-weight: bold;
}

.leagueTable tbody tr td:first-child,
.leagueTable tbody tr td:last-child {
  color: #000;
}

.leagueTable .logo {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leagueTable .logo .logo_border {
  width: 40px;
  height: 40px;
  border: 2px solid #d6d6d6;
}

.leagueTable .logo .logo_border img {
  width: 34px;
  height: 34px;
}

td.TeamLogoName,
.tournament-table-td-url {
  display: flex;
  align-items: center;
  border-bottom: none;
  border-right: none;
}

.numberTeam {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: 3px;
  color: #bdbdc0;
}

.tournament-table-td-url {
  text-decoration: none;
  color: inherit;
}

a.tournament-table-td-url:hover {
  text-decoration: underline;
}

.TeamLogoName h4 {
  margin-left: 10px;
}

/*Archive of Leagues end*/

/*Home*/

.background {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-container {
  padding-top: 40px;
  display: flex;
  /*flex-direction: row;*/
  /*align-items: flex-end;*/
  /*justify-content: flex-end;*/
  justify-content: space-between;
}

.feature-game {
  display: flex;
  align-items: flex-end;
}

.header-news {
  max-width: 275px;
  height: 360px;
  overflow: hidden;
}

.header-news {
  max-width: 275px;
  overflow: hidden;
}

/*.header-news-item {*/
.header-news-item,
.side-header-news-item {
  display: flex !important;
  flex-direction: row;
  text-decoration: none;
  /*width: 200px;*/
}

.container-for-sidebar-news {
  background: #2e2f3a;
  width: 390px;
}

.sidebar-news {
  background: #2e2f3a;
  max-width: 347px;
  margin: 0 auto;
}

.sidebar-news .header-news-item {
  width: 360px;
}

.sidebar-news-slider-img {
  width: 390px;
  height: 390px;
  background: #2e2f3a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sidebar-news-slider-img .sidebar-news-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
}

.sidebar .sidebar-news-slider-img .sidebar-news-item img {
  width: 310px;
  height: 270px;
}

.sidebar-news-slider-img .sidebar-news-item p {
  margin-top: 15px;
}

.sidebar-news-active p {
  color: #ffffff !important;
  border-left: 1px solid #fec722 !important;
}

.sidebar-news-slider-img .sidebar-news-item .border-bottom {
  width: 80%;
  margin-top: 15px;
  border-bottom: 1px solid #ffffff;
  opacity: 0.1;
}

.slick-vertical .slick-slide {
  border: none;
}

.header-slider-news-with-pictures-wrapper {
  margin-left: 20px;
  width: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.17);
  border-radius: 7px;
}

.header-slider-news-with-pictures-container {
  width: 725px;
}

.header-slider-news-with-pictures-item {
  width: 725px;
  position: relative;
}

.header-slider-news-with-pictures-item img {
  width: 725px;
  height: 350px;
  border-radius: 7px;
}

.header-slider-news-with-pictures-item p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 10px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.header-news-item:hover p,
.side-header-news-item p {
  border-left: 1px solid #fec722;
  color: #ffffff;
}

.header-news-item span,
.side-header-news-item span {
  /*width: 40px;*/
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
  color: #ffffff;
}

.side-header-news-item.for_correctTime span {
  width: 63px;
}

.header-news-item span {
  width: 80px;
}

.header-news-item p,
.side-header-news-item p {
  width: 198px;
  height: 90px;
  /*padding: 10px 0 20px 10px;*/
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-left: 1px solid #ffffff;
  color: #cccccc;
  transition: all 0.5s;
}

.home_featureGame {
  width: 1200px;
  margin: 0 auto 10px;
  display: flex;
  flex-direction: row;
}

.home_featureGame h4 svg {
  margin-right: 5px;
}

.home_featureGame h4 span,
.home h4 i {
  color: #000;
}

.home_featureGame .info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #fec722;
  width: 408px;
  height: 130px;
}

.home_featureGame .info:before {
  content: '';
  position: absolute;
  right: -119px;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 130px 0 0 120px;
  border-color: transparent transparent transparent #fec722;
}

.home_featureGame .timer {
  color: #fff;
  width: 680px;
  margin-left: 115px;
  height: 130px;
  border-bottom: 5px solid #fec722;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  text-align: center;
}

.timer .comingSoon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_featureGame .timer .section {
  color: #fff;
  position: relative;
  bottom: 15px;
}

.home_featureGame .timer .section.time-divder {
  height: 117px;
}

.home_featureGame .timer .section.time-divder h3 {
  height: 100%;
}

.home_featureGame .timer .dateNumber {
  width: 86px;
}

.home_featureGame .timer .section h3 {
  font-size: 85px;
  font-weight: 300;
}

.academies-list-slider {
  width: 1000px;
  margin: 0 auto 50px;

}

.academies-list-slider-item {
}

.academies-list-slider-item img {
  width: 90px;
  height: 90px;
}

.container.home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.sidebar {
  width: 390px;
}

.sidebar .section, .social-section.section {
  width: 390px;
  margin-bottom: 20px;
}

.sidebar .section .title_section, .social-section.section .title_section {
  background-color: #24252f;
  color: #fff;
  border-left: 3px solid #fec722;
  border-bottom: 1px solid #fec722;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sidebar .section .title_section h3, .social-section.section .title_section h3 {
  padding: 15px;
}

.sidebar .section .recent_Navigation, .social-section.section .recent_Navigation {
  position: relative;
  width: 40px;
  background-color: #fec722;
  height: 53px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  transition: all 0.5s;
}

.sidebar .section .recent_Navigation:before, .social-section.section .recent_Navigation:before {
  content: '';
  position: absolute;
  left: -42px;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 53px 42px;
  border-color: transparent transparent #fec722 transparent;
}

.sidebar .section .recent_Navigation span {
  display: flex;
  height: 53px;
  padding: 0 2px;
  align-items: center;
  color: #24252f;
  cursor: pointer;
}

.sidebar .section .recent_Navigation:hover {
  width: 44px;
}

.sidebar .section .recent_Navigation span:hover {
  color: #fff;
}

.sidebar .section .list {
  background-color: #24252f;
}

.sidebar .section .result_item {
  display: flex;
  flex-direction: column;
  background-color: #2e2f3a;
  border: 1px solid #24252f;
}

.sidebar .section .result_item .date {
  text-align: center;
  position: relative;
  padding: 18px 7px;
  color: #fec722;
  font-weight: bold;
  width: 95%;
  margin: 0 auto 3px;
}

.sidebar .section .result_item .date:before {
  position: absolute;
  content: '';
  height: 1px;
  background: #fff;
  opacity: 0.1;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.sidebar .section .result_item .infoGame {
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.sidebar .section .result_item .infoGame .score {
  color: #fff;
}

.sidebar .section .result_item .infoGame .score h3 {
  font-weight: normal;
}

.sidebar .section .result_item .infoGame .team {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  padding: 3px;
}

.sidebar .section .result_item .infoGame .team .logo_border,
.sidebar .section .teamItem .teamLogoAndName .logo_border {
  margin-right: 5px;
  width: 40px;
  height: 40px;
}

.sidebar .section .result_item .infoGame .team img,
.sidebar .section .teamItem .teamLogoAndName .logo_border img {
  width: 34px;
  height: 34px;
}

.sidebar .section .result_item .infoGame .team h3 {
  margin: 0 4px;
  font-size: 14px;
  width: 105px;
  text-align: center;
}

.sidebar .section img {
  width: 40px;
  height: 40px;
}

.sidebar .section .list {
  border-bottom: 3px solid #fec722;
}

.sidebar .section .teamItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 10px;
  align-items: center;
  position: relative;
  background-color: #2e2f3a;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 1px;
  color: #fff;
  border-radius: 4px 4px 0 0;
}

.sidebar .section .teamItem:after {
  position: absolute;
  content: '';
  width: 90%;
  height: 1px;
  background: #fff;
  opacity: 0.1;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.sidebar .section .teamItem:hover {
  background-color: rgba(108, 118, 136, 0.1);
}

.sidebar .section .teamItem:last-child {
  border-bottom: none;
}

.sidebar .section .teamItem .teamLogoAndName {
  display: flex;
  width: 250px;
  flex-direction: row;
  align-items: center;
}

.sidebar .section .teamItem .teamLogoAndName span:first-child {
  padding: 0 15px;
}

.sidebar .section .teamItem .teamLogoAndName span:first-child,
.sidebar .section .teamItem .teamLogoAndName + span {
  font-weight: bold;
}

.home .item_description {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.home .newsItem.simpleNews {
  margin-left: 10px;
}

.home .newsItem.bestNews {
  width: 790px;
  height: 445px;
}

.home .simpleNews:first-child,
.home .simpleNews:nth-child(2n) {
  margin-left: 0;
}

.home_title {
  padding: 5px 0;
  border-bottom: 1px solid #fec722;
}

.home_title.only-text-title {
  border-bottom: none;
}

.home .newsList:after {
  content: '';
  display: block;
  clear: both;
}

.home_video_list {
  padding-top: 15px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.home_video_item {
  width: 23%;
}

.social-networks-wrapper {
  flex-wrap: wrap;
  padding-top: 20px;
  border-top: 1px solid #fec722;
  /*height: 20px;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.social-networks-container {
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: center;
}

.social-networks-container .social-section {
  border-bottom: 3px solid #fec722;
  margin-bottom: 10px;
  width: 100%;
}

.social-networks-container .recent_Navigation {
  align-items: center;
  font-size: 20px;
}


.home-container-partners {
  /*height: 200px;*/
  /*width: 100%;*/
  /*margin-bottom: 10px;*/
  margin-top: 20px;
}

.home-container-partners h2 {
  margin-bottom: 10px;
}

.home-container-partners .partners-list {
  display: flex;
  flex-direction: row;
  /*justify-content: center;*/
  flex-wrap: wrap;
  width: 100%;
}

.widget-instagram {
  width: 350px;
}

.partners-list-item {
  width: 150px;
  background-color: #fff;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 10px 10px;
}

.partners-list-item img {
  width: 100%;
  /*height: 200px;*/
}

/*Home end*/

/*Schedule*/

.gameList_schedule {
  /*background-color: aqua;*/
  /*width: 900px;*/
  /*margin: 20px auto;*/
}

.month_sch_block {
  margin: 20px 0;
}

.downloadPDFBtn_sch {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.downloadPDFBtn_sch img {
  width: 16px;
  height: 16px;
}

.month_sch_header {
  width: 100%;
  display: block;
  padding: 5px;
  background-color: #FE901A;
  font-weight: bold;
  font-size: 13px;
}

.week_sch_header {
  width: 100%;
  display: block;
  padding: 1px 5px;
  background-color: #fec722;
  font-weight: bold;
  font-size: 12px;
}

.sch_container_425 {
  display: none;
  width: 100%;
  margin: 10px auto 10px auto;
}

.gameItem_schedule_mobile {
  padding: 5px 10px;
  /*background-color: #81c342;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
}

/*.defStateText_mob_sch {*/
  /*margin-bottom: 3px;*/
/*}*/

.gameItem_schedule_mobile .sch_mob_column {
  display: flex;
  flex-direction: column;
}

.sch_mob_column.mob_sch_datetime {
  width: 70px;
  text-align: center;
  padding: 3px;
  border-radius: 7px;
  background-color: #F1EFF1;
  margin-right: 10px;
}

.sch_mob_column.mob_sch_division {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 3px;
  border-radius: 7px;
  background-color: #F1EFF1;
  margin-left: 10px;
}

.sch_mob_column.mob_sch_gameInfo {
  width: 65%;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
}

.mob_sch_gameInfo a {
  text-decoration: none;
  color: #000;
}

.mob_sch_gameInfo i.size {
  width: 10px;
}

.mob_sch_time_gameItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
}

.custom_sch_circle {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: #FE901A;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gameItem_schedule {
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  align-items: center;
  /*padding: 3px 5px;*/
  margin-bottom: 1px;
  /*border: 1px solid #ededed;*/
  /*border-radius: 7px;*/
  font-size: 13px;
}

.day_color_Sat {
  color: #d6a40e;
}

.day_color_Sun {
  color: #ff0000;
}

.gameItem_schedule:nth-child(odd) {
  background-color: #F1EFF1;
}

.gameItem_schedule:nth-child(even) {
  background-color: #E0E1E0;
}

.gameItem_schedule h4 {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: normal;
  /*width: 200px;*/
  /*text-align: center;*/
  /*font-size: 14px;*/
}

.sch_mob_row_team {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  margin-left: -4px;
}

.sch_mob_row_team.space-btw_sch_mob {
  justify-content: space-between;
}

.gameItem_schedule .column1 {
  padding: 3px 5px;
  width: 5%;
  /*display: flex;*/
  /*justify-content: center;*/
  border-right: 1px solid #fff;
}

.gameItem_schedule .column2 {
  padding: 3px 5px;
  width: 12%;
  border-right: 1px solid #fff;

}
.gameItem_schedule .column3 {
  width: 12%;
  display: flex;
  justify-content: flex-end;
  border-right: 1px solid #fff;
  /*padding-right: 15px;*/
  padding: 3px 5px;
}
.gameItem_schedule .column4 {
  width: 24%;
  /*padding-right: 15px;*/
  padding: 3px 15px 3px 5px;
  display: flex;
  justify-content: flex-end;
  border-right: 1px solid #fff;

}
.gameItem_schedule .column5 {
  padding: 3px 5px 3px 15px;
  width: 24%;
  /*padding-left: 15px;*/
  border-right: 1px solid #fff;
}
.gameItem_schedule .column6 {
  width: 4%;
  border-right: 1px solid #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.gameItem_schedule .column7 {
  width: 28%;
  padding: 3px 5px;
}

.gameItem_schedule a {
  text-decoration: none;
  color: #000;
  width: 170px;
}

.date_time_schedule_game {
}

.date_time_schedule_game span {
  /*background-color: #fec722;*/
  padding: 5px;
}

.date_time_schedule_game span:first-child {
  /*background-color: #ededed;*/
  border-radius: 7px 0 0 7px;
}

/*Schedule_end*/

.reset_password_block {
  max-width: 500px;
  padding: 0 15px;
  margin: 15px auto 0;
}

.error_text {
  text-align: center;
  color: #ff0000;
  font-size: 12px;
  margin-top: -10px;
}

.coachesSelectWrapper {
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
}

.coachesSelectWrapper > div {
  padding-right: 10px;
  padding-left: 10px;
  width: 50%;
}

.instagram-media.instagram-media-rendered {
  min-width: 320px!important;
}

/*Adaptation*/

@media only screen and (max-width: 1199px) {
  .container {
    width: 1024px;
  }
  nav ul a,
  .prototypeLink {
    padding: 14px;
    font-size: 15px;
  }
  .otherPages .dropdownList {
    margin-top: 14px;
  }
  .home_featureGame {
    width: 1000px;
  }
  .home_page_news {
    width: 600px;
  }
  .home_page_news .newsList {
    width: 600px;
  }
  .home .newsItem.bestNews {
    width: 600px;
    height: 330px;
  }
  .home .newsItem {
    width: 295px;
    height: 295px;
  }
  .item_description h4 {
    font-size: 14px;
  }
  .leagueTable:last-child {
    margin-bottom: 20px;
  }
  .academiesItem:first-child {
    margin-left: 10px;
  }
  .academiesList {
    justify-content: space-between;
  }
  .player_avatar {
    width: 250px;
    height: 250px;
  }
  .info img {
    width: 250px;
    height: 250px;
  }
  .details .description,
  .head_detail .team_description {
    width: 685px;
  }
  .changesInfo .twoRows h3 {
    width: 700px;
  }
  .info_player {
    width: 700px;
  }
  .newsItem {
    width: 300px;
    height: 300px;
    margin-left: 60px;
  }
  .article {
    width: 600px;
  }
  .article img {
    width: 600px;
  }
  .album,
  .album .image-gallery-slide img {
    width: 1000px;
  }
  .video {
    width: 450px;
    height: 270px;
    margin-right: 70px;
  }
  .leagueItem {
    margin-left: 20px;
  }
  .requestItem .team {
    width: 135px;
  }
  .otherPages .dropdownList a {
    padding: 10px 5px;
  }
  .player_avatar .corner_team {
    width: 120px;
  }
}

@media only screen and (max-width: 1023px) {
  .container {
    width: 875px;
  }
  .otherPages .dropdownList {
    right: 0;
  }
  nav ul a,
  .prototypeLink {
    padding: 4px;
    display: block;
    font-size: 15px;
  }
  .otherPages .dropdownList a {
    font-size: 13px;
  }
  .otherPages .dropdownList {
    margin-top: 0;
  }
  .home_featureGame {
    width: 845px;
  }
  .home_featureGame .timer .section h3 {
    font-size: 70px;
  }
  .home_page_news,
  .home_page_news .newsList {
    width: 530px;
  }
  .home .newsItem.bestNews {
    width: 530px;
  }
  .home .newsItem {
    width: 260px;
    height: 260px;
  }
  .sidebar {
    width: 325px;
  }
  .sidebar .section {
    width: 325px;
    margin-bottom: 20px;
  }
  .sidebar .section .result_item .infoGame .team:first-child {
    flex-direction: column-reverse;
  }
  .sidebar .section .result_item .infoGame .team {
    flex-direction: column;
  }
  .item_description h4 {
    font-size: 12px;
  }
  .academiesList {
    justify-content: inherit;
  }
  .academiesItem:first-child {
    margin-left: 0;
  }
  .academiesItem:nth-child(4n) {
    margin-left: 0;
  }
  .academiesItem {
    margin-left: 0;
    margin-right: 0;
  }
  .academiesItem {
    width: 217px;
  }
  .academiesItem .backgroundImage {
    width: 215px;
  }
  .changesInfo .twoRows h3 {
    width: 600px;
  }
  .info_player {
    width: 600px;
  }
  .newsItem {
    width: 270px;
    height: 270px;
    margin-left: 32px;
  }
  .article {
    width: 500px;
  }
  .article img {
    width: 500px;
  }
  .album,
  .album .image-gallery-slide img {
    width: 875px;
  }
  .video {
    width: 400px;
    height: 270px;
    margin-right: 70px;
  }
  .leagueItem,
  .leagueItem:first-child {
    margin-left: 70px;
  }
  .leagueItem:nth-child(4n) {
    margin-left: 70px;
  }
  .item_contact span {
    padding: 0 4px;
  }
  .navigationProfile .formsButton {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .requestItem {
    flex-wrap: wrap;
  }
  .requestItem .requestConfirm {
    width: 100%;
  }
  .sidebar-news-slider-img {
    width: 325px;
    height: 325px;
  }
  .sidebar .sidebar-news-slider-img .sidebar-news-item img {
    width: 245px;
    height: 225px;
  }
  .sidebar-news-slider-img .sidebar-news-item p {
    width: 245px;
  }
  .container-for-sidebar-news {
    width: 325px;
  }
  .header-news-item p,
  .side-header-news-item p {
    padding-right: 5px;
  }
}

@media only screen and (max-width: 874px) {
  .container {
    width: 700px;
  }
  nav {
    width: 500px;
    margin-right: 15px;
  }
  nav ul {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .otherPages .dropdownList {
    left: 0;
  }
  .home_featureGame {
    width: 660px;
  }
  .home .newsItem.bestNews {
    width: 400px;
    height: 300px;
  }
  .home .newsItem {
    width: 400px;
    height: 400px;
  }
  .home_page_news,
  .home_page_news .newsList {
    width: 400px;
  }
  .home .newsItem.simpleNews {
    margin-left: 0;
  }
  .sidebar,
  .sidebar .section {
    width: 280px;
  }
  .gamesList .game .team {
    flex-direction: column-reverse;
  }
  .gamesList .game .team:first-child {
    flex-direction: column;
  }
  .gamesList.leagueGames .game .info_game .referee {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title .header2 {
    font-size: 30px;
  }
  .academiesItem {
    margin-left: 4px;
    margin-right: 4px;
  }
  .academiesItem {
    width: 224px;
  }
  .academiesItem .backgroundImage {
    width: 222px;
  }
  .academiesItem:nth-child(4n),
  .academiesItem:first-child {
    margin-left: 4px;
  }
  .academyDetail .details .logo,
  .head_detail .logo {
    margin-right: 10px;
  }
  .details .description,
  .head_detail .team_description {
    width: 550px;
  }
  .academyDetail .details,
  .head_detail {
    flex-direction: column;
    align-items: center;
  }
  .details .description,
  .head_detail .team_description {
    width: 425px;
    flex-direction: column;
    align-items: center;
  }
  .changesInfo .twoRows h3 {
    width: 420px;
  }
  .info_player {
    width: 420px;
    flex-direction: column;
    align-items: center;
  }
  .newsItem {
    width: 320px;
    height: 320px;
    margin-left: 60px;
  }
  .newsItem:nth-child(odd) {
    margin-left: 0;
  }
  .newsItem:nth-child(even) {
    margin-left: 60px;
  }
  .article {
    width: 400px;
  }
  .article img {
    width: 400px;
  }
  .album,
  .album .image-gallery-slide img {
    width: 700px;
  }
  .album .image-gallery-thumbnails-container img {
    width: 69px;
    height: 30px;
  }
  .video {
    width: 320px;
    height: 250px;
    margin-right: 60px;
  }
  .leagueItem,
  .leagueItem:first-child {
    margin-left: 20px;
  }
  .leagueItem:nth-child(4n) {
    margin-left: 20px;
  }
  .contact_form {
    width: 700px;
  }
  .contactUs .row input {
    width: 320px;
  }
  .requestItem .other {
    margin-bottom: 4px;
  }
  .ac_Requests .requestItem .team {
    width: 250px;
  }
  .head_detail .team_description .info_block .row:last-child {
    justify-content: flex-start;
  }
  .sidebar-news-slider-img {
    width: 280px;
  }
  .container-for-sidebar-news {
    width: 280px;
  }
}

@media only screen and (max-width: 699px) {

  .modal.score_form_league_editor {
    width: 100%;
    padding: 10px;
  }

  .modal.info_picker_for_pdf {
    width: 100%;
  }

  .score_form_league_editor .score_form_row.first_level {
    width: 100%;
  }

  .score_form_league_editor .score_form_row span {
    font-size: 13px;
    width: 90px;
  }

  .roster-player-item .checkedIdDocument {
    width: 100%;
    text-align: center;
  }

  /*SCHEDULE styles*/
  .month_sch_block {
    display: none;
  }

  .sch_container_425 {
    display: block;
  }
  /*SCHEDULE styles end*/

  /*ROSTER styles*/
  .modal.import-players-wrapper {
    width: 100%;
  }
  .organizer-main-wrapper {
    width: 237px;
  }
  .hide_for_mobile_roster {
    display: none;
  }
  .roster-player-item.roster_view_for_mobile {
    flex-direction: column;
  }

  .roster-player-item.roster_view_for_mobile h4 {
    width: auto;
  }

  .organizer-header-manager-block .header-manager-item span {
    font-size: 0;
  }

  .organizer-main-container .info_profile {
    flex-direction: column;
    width: 100%;
  }

  .column-roster-form-edit-files .avatarUser {
    width: 217px!important;
  }
  /*ROSTER styles end*/
  .container {
    width: 425px;
  }
  header .logo {
    text-align: center;
    width: 100px;
  }
  nav ul {
    justify-content: center;
  }
  .signIn {
    height: 320px;
    overflow-y: scroll;
  }
  .container.headerNavigation {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .headerNavigation .row {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  nav {
    width: 425px;
    margin-right: 0;
  }
  header .container {
    flex-direction: column;
    position: relative;
  }
  .mobile_menu ul {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .item_sigIn {
    width: 100%;
  }
  .closeSignIn {
    position: absolute;
    top: 0;
    right: 0;
  }
  .forMobileMenu {
    display: block;
    padding: 15px;
  }
  .forMobileMenu h4 i {
    margin-right: 5px;
  }
  .mobile_menu {
    display: none;
  }
  .modal {
    width: 425px;
  }
  .home_featureGame .info {
    width: 425px;
  }
  .home_featureGame {
    width: 425px;
    flex-direction: column;
  }
  .home_featureGame .timer {
    margin-left: 0;
    width: 425px;
  }
  .home_featureGame .info:before {
    display: none;
  }
  .container.home {
    flex-direction: column-reverse;
  }
  .home_page_news,
  .home_page_news .newsList {
    margin-right: 0;
    width: 425px;
  }
  .sidebar,
  .sidebar .section {
    width: 425px;
  }
  .sidebar .section .result_item .infoGame .team,
  .sidebar .section .result_item .infoGame .team:first-child {
    flex-direction: row;
  }
  .home .newsItem,
  .home .newsItem.bestNews {
    width: 425px;
  }
  .home_video_list {
    flex-direction: column;
  }
  .home_video_item {
    width: 425px;
    height: 325px;
    margin: 0 auto 20px;
  }
  .leagueTable thead th:nth-child(6),
  .leagueTable thead th:nth-child(7),
  .leagueTable thead th:nth-child(8) {
    display: none;
  }
  .leagueTable tbody tr td:nth-child(6),
  .leagueTable tbody tr td:nth-child(7),
  .leagueTable tbody tr td:nth-child(8) {
    display: none;
  }
  .nextTab {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gamesList.leagueGames .game {
    flex-direction: column;
    align-items: center;
  }
  .gamesList.leagueGames .game .team h4 {
    text-align: left;
  }
  .gamesList .game .team:first-child {
    flex-direction: row;
  }
  .gamesList .game .team {
    flex-direction: row-reverse;
  }
  .academiesItem {
    width: 200px;
  }
  .academiesItem .backgroundImage {
    width: 198px;
  }
  .academiesItem:nth-child(2n) {
    margin-right: 0;
  }
  .academiesItem .description .svg {
    top: -38px;
  }
  .academiesItem .description {
    width: 200px;
  }
  .academyDetail .teamAge {
    width: 425px;
  }
  .academyDetail .team {
    width: 425px;
  }
  .academyDetail .teamItem .header_description {
    flex-direction: column;
  }
  .academyDetail .teamItem .description {
    align-items: center;
  }
  .academyDetail .teamItem .description p {
    width: 290px;
  }
  .info_profile,
  .minContainer {
    width: 425px;
  }
  .teams_list .team_item {
    flex-direction: column;
    align-items: center;
  }
  .team_info {
    width: 425px;
    margin-left: 0;
  }
  .info.changesInfo {
    flex-direction: column;
    align-items: center;
  }
  .changesInfo .twoRows h3 {
    margin-left: 0;
    padding-left: 0;
  }
  .game {
    flex-direction: column;
    align-items: center;
  }
  .info.minContainer {
    flex-direction: column;
  }
  .newsItem {
    width: 425px;
    height: 425px;
    margin-left: 0;
    margin-right: 0;
  }
  .newsItem:nth-child(even) {
    margin-left: 0;
  }
  .newsItem:nth-child(4n) {
    margin-left: 0;
  }
  .article {
    width: 425px;
  }
  .article img {
    width: 425px;
  }
  .container.home.articleDetail {
    flex-direction: column;
  }
  .album,
  .album .image-gallery-slide img {
    width: 425px;
    height: 300px;
  }
  .album .image-gallery-thumbnails-container img {
    width: 70px;
    height: 30px;
  }
  .video {
    width: 425px;
    margin: 0 auto 60px;
  }
  .leagueItem {
    width: 100%;
  }
  .leagueItem,
  .leagueItem:first-child {
    margin: 0 auto 30px;
  }
  .leagueItem:nth-child(4n) {
    margin: 0 auto 30px;
  }
  .howToContactUs {
    flex-direction: column;
  }
  .item_contact {
    width: 425px;
    margin-bottom: 20px;
    height: 350px;
  }
  .contact_form {
    width: 425px;
  }
  .contactUs .row {
    flex-direction: column;
  }
  .contactUs .row input {
    width: 100%;
  }
  .navigationProfile {
    flex-direction: column;
  }
  .navigationProfile .formsButton {
    margin: 5px auto;
  }
  .navigationProfile a:first-child {
    margin: 5px auto;
  }
  .navigationProfile a:last-child {
    margin: 5px auto;
  }
  .info_profile .column {
    width: 260px;
  }
  .requestItem .team {
    width: 160px;
  }
  .pickFile .addFile {
    margin-right: 5px;
  }
  .info_profile .column .row.adaptationColumn {
    flex-direction: column;
    justify-content: center;
  }
  .adaptationColumn .avatarUser {
    margin-bottom: 10px;
  }

  .ac_teamItem .info_team .row {
    flex-direction: column;
  }
  .ac_teamItem .row.containerRow {
    flex-direction: row;
  }
  .ac_teamItem .row.containerRow .inRow {
    flex-direction: row;
  }
  .ac_teamItem .info_team .row h4 {
    margin-bottom: 10px;
  }
  .ac_teamItem .row:first-child {
    align-items: center;
  }

  .command_structure .structure {
    justify-content: center;
  }

  .gamesList .game .logo,
  .gamesList .game .logo .logo_border {
    width: 40px;
    height: 40px;
  }
  .gamesList .game .logo .logo_border img {
    width: 34px;
    height: 34px;
  }
  .gamesList .game {
    font-size: 14px;
  }
  .gamesList.personal_usage .game .team h3 {
    width: 135px;
    min-width: 135px;
    font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
  .gamesList.personal_usage .game {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .gamesList.leagueGames .game {
    flex-direction: row;
    justify-content: center;
  }
  .gamesList.personal_usage .game .team:first-child {
    flex-direction: row-reverse;
  }
  .gamesList .game .team:first-child h4,
  .gamesList.personal_usage .game .team h3 {
    margin-left: 0;
  }
  .gamesList.personal_usage .game .team {
    width: 180px;
    justify-content: center;
  }
  .gamesList.leagueGames .game .team {
    width: 180px;
    justify-content: center;
    flex-direction: column;
  }
  .gamesList.leagueGames .game .team:last-child {
    flex-direction: column-reverse;
  }
  .gamesList.leagueGames .game .team .team_information {
    margin-top: 10px;
  }
  .gamesList.leagueGames .game {
    padding: 5px 10px;
  }
  .gamesList.leagueGames .game .team h4 {
    width: 104px;
    min-width: 104px;
    font-size: 14px;
    text-align: center;
  }
  .gamesList.leagueGames .game .referee {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .gamesList.leagueGames .game .referee a {
    display: block;
    text-align: center;
  }
  .gamesList.leagueGames .game .place {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .gamesList.personal_usage .game .team:last-child {
    order: 0;
  }
  .gamesList.personal_usage .game .info_game {
    order: 1;
  }
  .reset_password_block {
    max-width: 320px;
  }
  .header-container {
    justify-content: center;
  }
  .sidebar-news-slider-img {
    width: 425px;
    height: 445px;
  }
  .sidebar .sidebar-news-slider-img .sidebar-news-item img {
    width: 370px;
    height: 330px;
  }
  .sidebar-news-slider-img .sidebar-news-item p {
    width: 370px;
  }
  .container-for-sidebar-news {
    width: 425px;
  }
}

@media only screen and (max-width: 424px) {

  .academies-list-slider {
    width: 250px;
    margin: 0 auto 20px;
  }
  .container {
    width: 320px;
  }
  nav {
    width: 320px;
    margin: 0 auto;
  }
  .details .personal_info .aa-email {
    font-size: 13px;
  }
  nav ul li.logout {
    width: 80%;
    text-align: center;
  }
  .home_featureGame,
  .home_featureGame .info {
    width: 320px;
  }
  .home_featureGame .timer {
    width: 280px;
    margin: 0 auto;
  }
  .home_featureGame .timer .section h3 {
    font-size: 50px;
  }
  .home_featureGame .timer .section {
    bottom: 0;
  }
  .sidebar,
  .sidebar .section {
    width: 320px;
  }
  .sidebar .section .result_item .infoGame .team:first-child {
    flex-direction: column-reverse;
  }
  .sidebar .section .result_item .infoGame .team {
    flex-direction: column;
  }
  .home_page_news,
  .home_page_news .newsList {
    width: 320px;
  }
  .home .newsItem {
    width: 320px;
    height: 320px;
  }
  .home .newsItem.bestNews {
    width: 320px;
    height: 250px;
  }
  .home_video_item {
    width: 320px;
    height: 300px;
  }
  /*comment: leagueTable thead th:nth-child(2) .leagueTable tbody tr td:nth-child(2) */
  .leagueTable thead th:nth-child(5),
  .leagueTable thead th:nth-child(3),
  .leagueTable thead th:nth-child(4) {
    display: none;
  }
  .leagueTable tbody tr td:nth-child(5),
  .leagueTable tbody tr td:nth-child(3),
  .leagueTable tbody tr td:nth-child(4) {
    display: none;
  }
  /*unUath users */
  .leagueTable.unAuth thead th:nth-child(3),
  .leagueTable.unAuth thead th:nth-child(4),
  .leagueTable.unAuth thead th:nth-child(5),
  .leagueTable.unAuth thead th:nth-child(9) {
    display: none;
  }
  .leagueTableunAuth tbody tr td:nth-child(3),
  .leagueTable.unAuth tbody tr td:nth-child(4),
  .leagueTable.unAuth tbody tr td:nth-child(5),
  .leagueTable.unAuth thead th:nth-child(9) {
    display: none;
  }
  .leagueTable.unAuth tbody tr td:nth-child(2),
  .leagueTable.unAuth thead th:nth-child(2) {
    display: table-cell;
  }
  .gamesList .game {
    padding: 5px 10px;
    font-size: 13px;
  }
  .gamesList.leagueGames .game {
    padding: 5px 10px;
    font-size: 13px;
  }
  .gamesList.personal_usage .game .team,
  .gamesList.leagueGames .game .team {
    width: 149px;
  }
  .gamesList.leagueGames .game .team h4,
  .gamesList.personal_usage .game .team h3 {
    width: 104px;
    min-width: 104px;
  }
  .academiesList {
    justify-content: space-around;
  }
  .details .description,
  .head_detail .team_description {
    width: 320px;
  }
  .description,
  .details .aboutAcademy,
  .head_detail .team_description .description {
    width: 320px;
  }
  .academyDetail .teamAge {
    width: 320px;
  }
  .academyDetail .team {
    width: 320px;
  }
  .academyDetail .teamItem .description {
    width: 320px;
  }
  .academyDetail .teamItem {
    flex-direction: column;
  }
  .info_profile,
  .minContainer {
    width: 320px;
  }
  .team_info {
    width: 320px;
  }
  .head_info h4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .minContainer a.player {
    flex-direction: column;
  }
  .changesInfo .twoRows h3 {
    width: 320px;
  }
  .info_player {
    width: 320px;
  }
  .newsItem {
    width: 320px;
    height: 320px;
  }
  .article {
    width: 320px;
  }
  .article img {
    width: 320px;
    height: 250px;
  }
  .album,
  .album .image-gallery-slide img {
    width: 320px;
    height: 200px;
  }
  .video {
    width: 320px;
  }
  .item_contact {
    width: 320px;
  }
  .contact_form {
    width: 320px;
  }
  .info_profile .row {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .info_profile .avatarUser,
  .info_profile .column {
    width: 320px;
  }
  /*.info_profile .column { height: 385px; }*/
  .info_profile .column {
    margin-top: 10px;
  }
  .requestItem .team {
    margin-bottom: 5px;
  }
  .requestItem {
    flex-direction: column;
    align-items: center;
  }
  .requestItem .team {
    width: 100%;
    text-align: center;
  }
  .info_profile .column .row.adaptationColumn {
    align-items: center;
  }
  .sidebar-news-slider-img {
    width: 320px;
    height: 340px;
  }
  .sidebar .sidebar-news-slider-img .sidebar-news-item img {
    width: 220px;
    height: 200px;
  }
  .sidebar-news-slider-img .sidebar-news-item p {
    width: 220px;
  }
  .container-for-sidebar-news {
    width: 320px;
  }

  .hide-info-for-mobile {
    display: none;
  }
}

/*iframe {*/
  /*width: 100%;*/
/*}*/

.text_article img {
  height: 100%;
  width: 100%;
}

.uploadBtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

#importUserListFile {
  position: absolute;
  visibility: hidden;
  top: -9999px;
}
/*Adaptation end*/

.PDFDownloadBTN {
  max-height: 48px;
  background-color: #c0841d;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid #c0841d;
  color: #fff;
}

.PDFDownloadBTN > a {
  color: inherit;
  text-decoration: none;
}
