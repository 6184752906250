body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row!important;
}

.alignCenter {
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceAround {
  justify-content: space-around;
}

.justifyCenter {
  justify-content: center;
}

.flexEnd {
  justify-content: flex-end;
}

.flexWrap {
  flex-wrap: wrap;
}

.avatarUser>.row {
  flex-wrap: nowrap!important;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0c416f;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}